.slideshow .slide-nav {
  height: 100%;
  background-color: transparent;
  opacity: 0.25;
  text-transform: none;
  box-shadow: none;
}

.slideshow .slide-nav:hover {
  background-color: #e0e0e0;
  opacity: 1;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}