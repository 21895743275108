.home-page {
  margin-bottom: 2em;
}

/* Headline */
.home-page .headline {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 8em auto;
}

.home-page .elevator-pitch {
  font-weight: normal;
}

/* Topic Cards */
.home-topic-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.home-topic {
  margin: 1em;
  padding: 0;
  width: 336px;
  position: relative;
}

.home-topic .thumbnail {
  display: block;
  width: 336px;
  height: 208px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.05), 0px 2px 2px 0px rgba(0,0,0,0.035), 0px 3px 1px -2px rgba(0,0,0,0.03);
}

.home-topic .text {
  padding: 1em;
}

.home-topic .text h3 a,
.home-topic .text h3 a:hover {
  color: inherit;
}

.home-topic .text p {
  margin: 0;
}

/* Other Stuff */
.other-stuff {
  margin-top: 8em;
}