/* All screen sizes */
h1, h2, h3 {
  font-weight: 600;
}

h1.margin-bottom,
h2.margin-bottom,
h3.margin-bottom,
h4.margin-bottom,
h5.margin-bottom,
h6.margin-bottom,
.h1.margin-bottom,
.h2.margin-bottom,
.h3.margin-bottom,
.h4.margin-bottom,
.h5.margin-bottom,
.h6.margin-bottom {
  margin-bottom: 1rem;
}

button:focus {
  outline: none;
}

.app {
  height: 100vh;
  background-color: #fafbfd;
}

.watermark {
  user-select: none;
}

.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9000;
}

.menu-close-button {
  opacity: 0.25;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 99999;
  transition: opacity 0.5s !important;
}

.menu-close-button:hover {
  opacity: 1;
  cursor: pointer;
}

.menu-card {
  position: relative;
}

.menu a,
.menu a:visited {
  display: block;
  margin-bottom: 0.5em;
}

.menu-link.active {
  text-decoration: underline;
}

.main-container {
  height: 100%;
  overflow-y: auto;
}
.main {
  height: 100%;
  padding: 1em 0;
}

.footer {
  text-align: center;
  padding: 4em 0 1em 0;
}

.patreon-button:hover {
  opacity: 0.8;
}

/* Mobile */
@media (max-width: 800px) {
}

/* Desktop */
@media (min-width: 801px) {
  .main.embedded {
    padding: 0;
  }

  .menu-category {
    margin: 0 0.5em 0 0;
  }
}