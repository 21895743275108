.full-width {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.no-select {
  user-select: none;
}

.pass-through-click {
  pointer-events: none;
}

.bottom-shadow {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}

/* Mobile */
@media (max-width: 800px) {
  .hide-on-mobile {
    display: none;
  }
}

/* Desktop */
@media (min-width: 801px) {
  .hide-on-desktop {
    display: none !important;
  }
}

/* Animations */
.fade-in {
  animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fade-out;
  animation-duration: 2s;
  opacity: 0;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.background-green-to-initial {
  animation: background-green-to-initial;
  animation-duration: 3s;
}

@keyframes background-green-to-initial {
  from {
    background-color: #669942;
  }

  to {
    background-color: #d5d5d5;
  }
}